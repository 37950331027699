import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const Probono = () => {
    const seo = {
        metaDesc: 'Probono - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Probono - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <div class="banner banner-2 pt-3 pb-3" style={{ background: 'url(assets/img/banner/fielado2-1024x480-1.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div class="container">
                    <div class="row ">
                        <div class="col-12 col-md-8  mt-3">
                            <h1 class="w-clr">
                                Free Patent Filing Assistance Program available to small businesses and independent inventors

                            </h1>
                            <a href="provisional-builder.html">
                                <img src="assets/img/logos/button1-1.png" width="100%" alt="" />
                            </a>

                        </div>


                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <h1>Inventiv Pro Bono Program
                    </h1>
                    <p class="f-16">
                        Small businesses and inventors may be eligible to receive free legal help in filing and preparing a patent application.  This program is mandated by Section 32 of the America Invents Act, which requires that
                    </p>
                    <p class="f-16" style={{ paddingLeft: 20 + 'px', borderLeft: '5px solid #eee' }}>
                        The Director shall work with and support intellectual property law associations across the country in the establishment of pro bono programs designed to assist financially under-resourced independent inventors and small businesses.
                    </p>
                    <p class="f-16">
                        To meet this mandate, the US Patent and Trademark Office runs the Patent Pro Bono Program that matches patent lawyers with financially under-resourced inventors and small businesses for the purpose of securing patent protection.
                        <br /><br />
                        The program became fully operational on August 4, 2015, where President Obama published a press release announcing that the USPTO Patent Pro Bono Program now covers all 50 states at the White House’s website
                        <a href="https://www.whitehouse.gov/the-press-office/2015/08/04/fact-sheet-president-obama-announces-new-commitments-investors-companies">here.</a>
                        <br /><br />
                        <a href="https://www.uspto.gov/about-us/news-updates/record-number-practitioners-and-law-firms-helped-under-resourced-inventors">Director Kathi Vidal has called for broader participation with the PTO:</a> “While the work of those we recognize today will make a real impact for our country, we need to scale our programs and assistance. This is the key to unlocking U.S. competitiveness, creating jobs and economic prosperity, and fostering national security. If more people and firms get involved, I’m confident we can better the lives of all Americans and reduce the need for other legal aid.”

                    </p>
                    <img src="assets/img/banner/Picture1.jpg" width="100%" alt="" />
                    <p class="f-16">
                        (Source: USPTO)
                        <br /><br />
                        The USPTO has established a national network of regional programs independently managed by patent professionals that matches small-business inventors with volunteer patent professionals to secure patent protection. Each program offers services to residents of one or several states.
                        <br /><br />
                        For insights into inventions and the benefits of the PTO Pro Bono program, see:
                        <br />
                        <a href="https://www.uspto.gov/learning-and-resources/uspto-videos/intro-uspto-pro-bono-program" class="d-clr">https://www.uspto.gov/learning-and-resources/uspto-videos/intro-uspto-pro-bono-program</a>
                        <br />
                        <a href="http://www.youtube.com/watch?v=aP49MbYaXFQ" class="d-clr">http://www.youtube.com/watch?v=aP49MbYaXFQ</a>
                    </p>
                    <div class="">
                        <h2>Requirements</h2>
                        <p class="f-16">Admission requirements to each regional program may vary.&nbsp;The general requirements for admission are:</p>
                        <p class="f-16"><strong>Income</strong><br /> Your household gross income should not exceed three times the&nbsp;<a href="https://aspe.hhs.gov/poverty-guidelines">federal poverty levels</a>, though regional programs might have different criteria.</p>
                        <p class="f-16"><strong>Knowledge</strong><br /> One of these two methods must be used to prove your knowledge of the patent system:</p>
                        <ul class="f-16" style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                            <li>A provisional application already filed with the USPTO; or</li>
                            <li>Successful completion of the&nbsp;<a href="https://www.uspto.gov/video/cbt/certpck/index.htm">certificate training course</a>&nbsp;(<a href="https://www.uspto.gov/video/cbt/spanish-trngcrtcrse/">certificado de formacion en espanol</a>).</li>
                        </ul>
                        <p class="f-16"><strong>Invention</strong><br /> It is important to be able describe the specific features and the workings of your invention.</p>
                        <p class="f-16">As of May 2022, more than 3,400 inventors have been matched with a patent practitioner through the <a href="https://www.uspto.gov/about-us/news-updates/record-number-practitioners-and-law-firms-helped-under-resourced-inventors">Patent Pro Bono Program</a>. Since 2015, these legal professionals have filed nearly 1,800 patent applications on behalf of their pro bono clients. Importantly, not only is the program critical to expanding innovation, but it also has a proven record of expanding it inclusively. Last year, of the applicants who responded to a survey of those who have utilized the pro bono program, 30% identified as African American or Black; 14% as Hispanic; 5.6% as Asian or Pacific Islander; and 1.5% as Native American. Further, 41% of Patent Pro Bono Program applicants who responded to the survey identified as women.</p>
                        <p class="f-16">On average, the program accepts about 2000 probono applicants a year, so it is a competitive process.</p>
                        <p class="f-16">To get you a leg up in the PTO ProBono Program, Inventiv’s software can help you with the provisional application as well as the detailed description of&nbsp; the specific features and workings of your invention.</p>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td><em>You should speak to your regional program about the requirements for income, knowledge, or inventio</em>n.</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                    </div>
                    <a href="https://www.uspto.gov/patents/basics/using-legal-services/pro-bono/patent-pro-bono-program">
                        <img src="assets/img/banner/unnamed-1.png" width="100%" alt="" />
                    </a>
                    <p class="f-16">
                        To apply to your state’s regional program, simply click on this <a href="https://www.uspto.gov/patents-getting-started/using-legal-services/pro-bono/patent-pro-bono-program#Patent%20Pro%20Bono%20Coverage%20Map" class="d-clr"> map</a> at the USPTO and get access to online application forms for all regional programs.  For more information, visit <a href="https://www.uspto.gov/patents/basics/using-legal-services/pro-bono/patent-pro-bono-program" class="d-clr">https://www.uspto.gov/patents/basics/using-legal-services/pro-bono/patent-pro-bono-program</a>
                        <br /><br />
                        You can also try out  our software to assist you in writing your patent for free. Link to sign up is below
                    </p>
                    <a href="inventor-resources.html"> <img src="assets/img/logos/button1-1.png" width="100%" alt="" /></a>
                    <p class="mt-3">
                        Inventiv Foundation is helping our inventors by developing and making their software freely available to help inventors create their patent applications. Inventiv Foundation is a non profit organization supporting inventors. Our Tax ID is 83-0668793. We are a non profit charity 501(c)(3) Tax Exempt organization. Please donate to contribute to our quest to help inventors.

                    </p>
                </div>
            </section>
        </Layout>
    )
};

export default Probono;